body {
  font-family: 'Roboto', sans-serif;
  background: #ececec;
}

th:hover div,
th:hover span {
  color: white !important
}

.MuiTableSortLabel-active,
.MuiTableSortLabel-active span {
  color: #ffc107 !important
}

button:focus {
  outline: none !important;
}

.masked-input-wrapper {
  pointer-events: none;
}

.masked-input-disabled {
  color: #5c5c5c !important
}

.masked-input-wrapper>div::before {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42) !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}


/* Checkmark */

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 26px;
  height: 26px;
  top: -2px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@media screen and (max-width: 768px) {
  /* .header-title {
    background: #265C84;
    color: white;
    text-align: center;
    transition: all ease 0.5s;
  } */

  .MuiTableCell-body:not([data-testid]) {
    width: calc(30% - 20px) !important;
    margin: 0 !important;
    padding: 10px !important;
    font-weight: 900;
  }

  .MuiTableCell-body[data-testid] {
    width: calc(70% - 20px) !important;
    margin: 0 !important;
    padding: 10px !important;
  }

  .MuiTableCell-body {
    border-bottom: none !important;
  }


  .MuiTableCell-body p {
    margin: 0 !important;
  }

  .MuiToolbar-root {
    background: rgb(36, 92, 128);
    color: white;
  }

  .MuiToolbar-regular .MuiButtonBase-root .MuiSvgIcon-root {
    color: white !important;
  }

  .MuiInput-underline {
    border-bottom: solid 1px white;
  }

  .MuiPaper-rounded .MuiToolbar-regular div:first-of-type div:first-of-type .MuiSvgIcon-root {
    color: white !important;
  }

  .MuiToolbar-regular .MuiInput-underline .MuiInputBase-input {
    color: white !important;
  }

  .MuiToolbar-root div:first-of-type div:first-of-type>div {
    flex: 1 0 !important;
    height: 35px !important;
  }

  .MuiToolbar-regular div:first-of-type div:first-of-type>div .MuiIconButton-root {
    width: 20px;
  }

  .MuiTable-root tfoot tr td {
    padding: 0 !important;
  }

  .MuiPaper-root div:nth-child(3) .MuiTable-root tr {
    border: none !important;
    border-bottom: solid 1px rgb(0 0 0 / 10%) !important;
    display: block;
    padding: 20px 10px;
  }


}


@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.signCanvas {
  background: white;
}

.pac-container.pac-logo.hdpi {
  width: 400px !important;
}

/* .fade-enter {
  opacity: 1;
  transition: all 1s ease;
}

.fade-enter-active {
  opacity: 1;
  transition: all 1s ease;
}

.fade-exit {
  opacity: 0;
  transition: all 1s ease;
}

.fade-exit-active {
  opacity: 0;
  transition: all 1s ease;
}

.fade-appear {
  opacity: 1;
  transition: all 1s ease;
}

.fade-appear-active {
  opacity: 1;
  transition: all 1s ease;
} */